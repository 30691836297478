<template>
  <div class="double-bar-chart">
    <div class="double-bar-chart-header">
      <div class="flex-double-bar-chart">
        <div class="col">
          <h5 class="component-title">Today</h5>
        </div>
        <div class="col">
          <h5 class="component-title">Scenario</h5>
        </div>
      </div>
    </div>
    <div class="double-bar-chart-body">
      <div class="flex-header">
        <div class="col">
          <span class="component-value">{{ leftRevenue }}</span>
        </div>
        <div class="col">
          <span class="component-value">{{ rightRevenue }}</span>
        </div>
      </div>
      <div class="flex-content">
        <div class="col col-left">
          <div class="revenue" :style="{ 'height': `${getLeftRevenueHeight}%` }">
            <div class="profitability" :style="{ 'height': `${getLeftProfitabilityHeight}%` }"></div>
          </div>
        </div>
        <div class="col col-right">
          <div class="revenue" :style="{ 'height': `${getRightRevenueHeight}%` }">
            <div class="profitability" :style="{ 'height': `${getRightProfitabilityHeight}%` }"></div>
          </div>
        </div>
      </div>
      <div class="flex-footer">
        <div class="col">
          <span class="component-value">{{ (leftProfitability * 100).toFixed(1) }}%</span>
        </div>
        <div class="col">
          <span class="component-value">{{ (rightProfitability * 100).toFixed(1) }}%</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DoubleBarChart',
  props: {
    leftProfitability: Number,
    leftRevenue: Number,
    rightProfitability: Number,
    rightRevenue: Number,
    maxValue: Number,
  },
  data() {
    return {
      leftRevenueHeight: null,
      leftProfitabilityHeight: null,
      rightRevenueHeight: null,
      rightProfitabilityHeight: null,
    }
  },
  computed: {
    getLeftRevenueHeight() {
      this.leftRevenueHeight = (this.maxValue / this.leftRevenue)
      return (this.leftRevenueHeight === 1) ? 100 : (100 / this.leftRevenueHeight)
    },
    getLeftProfitabilityHeight() {
      this.leftProfitabilityHeight = ((this.leftProfitability * 100) / this.leftRevenueHeight)
      return this.leftProfitabilityHeight
    },
    getRightRevenueHeight() {
      this.rightRevenueHeight = (this.maxValue / this.rightRevenue)
      return (this.rightRevenueHeight === 1) ? 100 : (100 / this.rightRevenueHeight)
    },
    getRightProfitabilityHeight() {
      this.rightProfitabilityHeight = ((this.rightProfitability * 100) / this.rightRevenueHeight)
      return this.rightProfitabilityHeight
    },
  }
}
</script>