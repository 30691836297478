import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'

import './utils'

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)
// VTooltip.options.defaultTrigger = 'click'

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
Vue.component('VueSlider', VueSlider)

import './scss/main.scss'

new Vue(App).$mount('#app')
