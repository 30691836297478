<template>
  <div>
    <h1>Dashboard</h1>
    <div class="flex-test">
      <div class="col">
        <h1>Input table today</h1>
        <table style="width: 100%;" border="1">
          <tbody>
            <tr v-for="(d, index) in data.inputTableToday" :key="index">
              <td v-for="(c, i) in d" :key="i">{{ c }}</td>
            </tr>
          </tbody>
        </table>

        <h1>Weighted table today</h1>
        <table style="width: 100%;" border="1">
          <tbody>
            <tr v-for="(d, index) in weightedTableToday" :key="index">
              <td v-for="(value, i) in d" :key="i">{{ value }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col">
        <h1>Sliders</h1>
        <div v-for="(slider, index) in newControlValues" :key="index">
          <p>{{ slider.title }} = {{ slider.value }}</p>
          <vue-slider v-model="slider.value" :min="-5" :max="5" :step="1" @change="triggerSlider"></vue-slider>
        </div>
      </div>

      <div class="col">
        <h1>Input table implications</h1>
        <table style="width: 100%;" border="1">
          <tbody>
            <tr v-for="(d, index) in inputImplicationsTable" :key="index">
              <td v-for="(c, i) in d" :key="i">{{ c }}</td>
            </tr>
          </tbody>
        </table>

        <h1>Weighted table implications</h1>
        <table style="width: 100%;" border="1">
          <tbody>
            <tr v-for="(d, index) in weightedTableImplications" :key="index">
              <td v-for="(value, i) in d" :key="i">{{ value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div>
      <h1>Weighing table table</h1>
      <table style="width: 100%;" border="1">
        <tbody>
          <tr v-for="(d, index) in data.weighingTable" :key="index">
            <td v-for="(c, i) in d" :key="i">{{ c }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h1>Translations</h1>
    <div class="flex-test">
      <div class="col">
        <h1>Translations today</h1>
        <table style="width: 100%;" border="1">
          <tbody>
            <tr v-for="(d, index) in translationsTodayTable" :key="index">
              <td v-for="(c, i) in d" :key="i">{{ c }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col">
        <h1>Translations to-be</h1>
        <table style="width: 100%;" border="1">
          <tbody>
            <tr v-for="(d, index) in translationsToBeTable" :key="index">
              <td v-for="(c, i) in d" :key="i">{{ c }}</td>
            </tr>
          </tbody>
        </table>

        <h1>Calc table</h1>
        <table style="width: 100%;" border="1">
          <tbody>
            <tr v-for="(d, index) in translationsCalcTable" :key="index">
              <td v-for="(c, i) in d" :key="i">{{ c }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TestTables' 
  }
</script>