const data = {
  inputTableToday: [
    [36.52, 60.47, 217.80, 62.05, 752.00],
    [29.58, 56.24, 204.73, 59.57, 744.48],
    [6.94, 4.23, 13.07, 2.48, 7.52],
    [0.19, 0.07, 0.06, 0.04, 0.01],

    [0.05, 0, 0, 0, 0.3],
    [0.85, 0.01, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0.1, 0.99, 1, 1, 0.7],

    [0.1, 0.08, 0.09, 0.09, 0.05],
    [0.16, 0.02, 0.04, 0.04, 0.05],
    [0.1, 0.01, 0.05, 0.05, 0.02],
    [6.3, 90.3, 95.5, 3520, 453],
    [0.01, 0.02, 0.03, 0.03, 0],
    [null, 0.84, 0.62, 0.73, 0.65],
    [0.67, 0.46, 0.30, 0.82, null],
    [8870, 4630, 57, 21, 16700],
    [0.91, 0.90, 0.86, 1.52, 0.85]
  ],
  weighingTable: [
    [5, 4, 3, 2, 1],
    [0.03, 0.05, 0.08, 0.1, 1],
    [0.03, 0.06, 0.09, 0.12, 1],
    [0.03, 0.06, 0.09, 0.12, 1],
    [60, 120, 180, 240, 1000000],
    [1, 0.04, 0.03, 0.02, 0.01],
    [1, 0.75, 0.5, 0.25, 0],
    [1, 0.75, 0.5, 0.25, 0],
    [25, 50, 500, 5000, 10000000000],
    [0.8, 0.9, 1, 1.1, 5]
  ]
}

export default data
