<template>
  <div class="table-chart">
    <div class="table-chart-header">
      <div class="flex-table-chart">
        <div class="col">
          <h5 class="component-title">Today</h5>
        </div>
        <div class="col">
          <h5 class="component-title">Scenario</h5>
        </div>
      </div>
    </div>
    <div class="table-chart-body">
      <table>
        <tbody>
          <tr v-for="(row, index) in getValues" :key="index">
            <td :style="`background-color: rgba(136, 57, 171, ${ (row[0]).toFixed(1) }); ${(row[0] * 100 > 80) ? 'color: #ffffff' : ''}`">{{ parseInt((row[0] * 100)) }}%</td>
            <td :style="`background-color: rgba(232, 36, 197, ${ (row[1]).toFixed(1) }); ${(row[0] * 100 > 80) ? 'color: #ffffff' : ''}`">{{ parseInt((row[1] * 100)) }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableChartVue',
  props: {
    index: Number,
    geographicConcentrationToday: Array,
    geographicConcentrationScenario: Array
  },
  data() {
    return {
      todayValues: null,
      scenarioValues: null,
    }
  },
  computed: {
    getValues() {
      this.leftValue = this.geographicConcentrationToday
      this.rightValue = this.geographicConcentrationScenario

      let values =  [
        [this.leftValue[0][this.index], this.rightValue[0][this.index]],
        [this.leftValue[1][this.index], this.rightValue[1][this.index]],
        [this.leftValue[2][this.index], this.rightValue[2][this.index]],
        [this.leftValue[3][this.index], this.rightValue[3][this.index]]
      ]

      return values
    }
  }
}
</script>