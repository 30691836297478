import Vue from 'vue'

Vue.mixin({
  methods: {
    setSingleCell (array, rowIndex, index, value) {
      this.$set(array[rowIndex], index, value)
    },
    getLookUpValues (translations, sliderValues, rowType, columnType, cellValue) {

      // sliderValues[0].value = -5
      // sliderValues[1].value = -5
      // sliderValues[2].value = -5
      // sliderValues[3].value = -5
      // sliderValues[4].value =  -5
      // sliderValues[5].value = -5
      // sliderValues[6].value =  -5

      let sliderValuesArray = []

      sliderValues.forEach((slider) => {
        let sliderObject = {}
        sliderObject.name = slider.name
        sliderObject.value = (translations[slider.name][rowType][columnType][slider.value] / 100)

        sliderValuesArray.push(sliderObject)
      })

      let returningValue = 1

      sliderValuesArray.forEach(currentSlider => {
        returningValue = returningValue * currentSlider.value
      })

      return (returningValue * cellValue)
    },
    getTranslationsCalcTableVerticalSum (table, columnIndex) {
      return parseFloat(table[0][columnIndex]) + parseFloat(table[1][columnIndex]) + parseFloat(table[2][columnIndex]) + parseFloat(table[3][columnIndex])
    }
  }
})
