const data = {
  inputTableToday: [
    [0.59, 1.16, 2.21, 4.67, 21.60],
    [0.58, 1.11, 1.96, 3.92, 19.01],
    [0.01, 0.06, 0.24, 0.75, 2.59],
    [0.025, 0.05, 0.11, 0.16, 0.12],

    [0.8, 0.2, 0, 0.95, 0.15],
    [0.2, 0.7, 1, 0, 0.45],
    [0, 0, 0, 0, 0.1],
    [0, 0.1, 0, 0.05, 0.3],

    [0.05, 0.08, 0.2, 0.2, 0.1],
    [0.01, 0.02, 0.01, 0.03, 0.48],
    [0.01, 0.01, 0.02, 0.05, 0.18],
    [162, 22.2, 65.9, 65.9, 2.3],
    [0.005, 0.0035, 0.002, 0.001, 0.015],
    [null, 0.7, 0.95, 0.95, 0.4],
    [0.7, 0.35, 0.95, 0.4, null],
    [83500, 300, 1220, 5000, 27000],
    [0.05, 0.46, 0.65, 0.65, 1.37]
  ],
  weighingTable: [
    [5, 4, 3, 2, 1],
    [0.03, 0.05, 0.08, 0.1, 1],
    [0.03, 0.06, 0.09, 0.12, 1],
    [0.03, 0.06, 0.09, 0.12, 1],
    [60, 120, 180, 240, 1000000],
    [1, 0.04, 0.03, 0.02, 0.01],
    [1, 0.75, 0.5, 0.25, 0],
    [1, 0.75, 0.5, 0.25, 0],
    [25, 50, 500, 5000, 10000000000],
    [0.8, 0.9, 1, 1.1, 5]
  ]
}

export default data
