<template>
  <div class="wef-widget" id="wef-widget-app">

    <div class="wef-widget-header">
      <div class="header-navigation">
        <div class="flex-header">
          <div class="col" v-for="(tab, index) in tabs" :key="index">
            <button type="button" class="btn btn-header btn-header-text" :class="{ 'active': tab.active }" @click="changeTab(index)">{{ tab.title }}</button>
          </div>
        </div>
      </div>
      <div class="header-content">
        <h2 class="header-content-title">{{ getActiveTab.title }} supply chain in the world</h2>
        <p class="header-content-text">Play with sliders below and compare how the {{ getActiveTab.title.toLowerCase() }} supply chain looks today vs how it will look in the scenario with your assigned implications</p>
      </div>
    </div>

    <header id="header">

      <div class="wef-widget-controls-container">

        <div class="section-navigation">
          <div class="flex-section-navigation">
            <div class="col">
              <h3 class="section-navigation-title">Scenario implications settings</h3>
            </div>
            <div class="col">
              <button type="button" class="btn btn-transparent btn-flex-icon" @click="toggleSection(0)">
                <span class="section-navigation-title arrow">{{ (activeSections[0] ? 'Open' : 'Hide' ) }}</span>
                <span class="icon" :class="{ 'icon-chevron-bottom': activeSections[0], 'icon-chevron-top': !activeSections[0] }"></span>
              </button>
            </div>
          </div>
        </div>

        <div class="wef-widget-controls" :class="{ 'hidden': activeSections[0] }">

          <div class="flex-wef-widget-controls">
            <div class="col col-first">
              <h4 class="controls-title bold">Emerging Technologies</h4>
            </div>
            <div class="col col-second">
              <span class="controls-title">Lower</span>
            </div>
            <div class="col col-third">
              <span class="controls-title">Today</span>
            </div>
            <div class="col col-fourth">
              <span class="controls-title">Higher</span>
            </div>
          </div>

          <div class="flex-wef-widget-controls" v-for="i in 4" :key="`controls-1-${i}`">
            <div class="col col-first">
              <p class="controls-text">{{ controlValues[i - 1].title }}</p>
            </div>
            <div class="col col-second col-height">
              <span class="icon icon-tooltip" v-tooltip="{ content: controlValues[i - 1].tooltip[0].text, trigger: 'manual', show: controlValues[i - 1].tooltip[0].isOpen }" @click="tooltipEvent(i - 1, 0)"></span>
            </div>
            <div class="col col-third">
              <vue-slider v-model="controlValues[i - 1].value" :min="-5" :max="5" :step="1" @change="triggerSlider"></vue-slider>
            </div>
            <div class="col col-fourth col-height">
              <span class="icon icon-tooltip" v-tooltip="{ content: controlValues[i - 1].tooltip[1].text, trigger: 'manual', show: controlValues[i - 1].tooltip[1].isOpen }" @click="tooltipEvent(i - 1, 1)"></span>
            </div>
          </div>

          <div class="flex-wef-widget-controls">
            <div class="col col-first">
              <h4 class="controls-title bold">Environmental Sustainability</h4>
            </div>
          </div>

          <div class="flex-wef-widget-controls" v-for="i in 2" :key="`controls-2-${i}`">
            <div class="col col-first">
              <p class="controls-text">{{ controlValues[4 + i - 1].title }}</p>
            </div>
            <div class="col col-second col-height">
              <span class="icon icon-tooltip" v-tooltip="{ content: controlValues[4 + i - 1].tooltip[0].text, trigger: 'manual', show: controlValues[4 + i - 1].tooltip[0].isOpen }" @click="tooltipEvent(4 + i - 1, 0)"></span>
            </div>
            <div class="col col-third">
              <vue-slider v-model="controlValues[4 + i - 1].value" :min="-5" :max="5" :step="1" @change="triggerSlider"></vue-slider>
            </div>
            <div class="col col-fourth col-height">
              <span class="icon icon-tooltip" v-tooltip="{ content: controlValues[4 + i - 1].tooltip[1].text, trigger: 'manual', show: controlValues[4 + i - 1].tooltip[1].isOpen }" @click="tooltipEvent(4 + i - 1, 1)"></span>
            </div>
          </div>

          <div class="flex-wef-widget-controls">
            <div class="col col-first">
              <h4 class="controls-title bold">Globalisation Reconfigured</h4>
            </div>
          </div>

          <div class="flex-wef-widget-controls">
            <div class="col col-first">
              <p class="controls-text">{{ controlValues[6].title }}</p>
            </div>
            <div class="col col-second col-height">
              <span class="icon icon-tooltip" v-tooltip="{ content: controlValues[6].tooltip[0].text, trigger: 'manual', show: controlValues[6].tooltip[0].isOpen }" @click="tooltipEvent(6, 0)"></span>
            </div>
            <div class="col col-third">
              <vue-slider v-model="controlValues[6].value" :min="-5" :max="5" :step="1" @drag-end="triggerSlider"></vue-slider>
            </div>
            <div class="col col-fourth col-height">
              <span class="icon icon-tooltip" v-tooltip="{ content: controlValues[6].tooltip[1].text, trigger: 'manual', show: controlValues[6].tooltip[1].isOpen }" @click="tooltipEvent(6, 1)"></span>
            </div>
          </div>

        </div>

        <div class="wef-widget-column-header-container">
          <div class="wef-widget-column-header">
            <div class="flex-column-header">
              <div class="col">
                <h3 class="column-header-title">EXTRACTION</h3>
                <p class="column-header-text">{{ getActiveTab.locations[0] }}</p>
              </div>
              <div class="col">
                <h3 class="column-header-title">PROCESSING</h3>
                <p class="column-header-text">{{ getActiveTab.locations[1] }}</p>
              </div>
              <div class="col">
                <h3 class="column-header-title">Forming</h3>
                <p class="column-header-text">{{ getActiveTab.locations[2] }}</p>
              </div>
              <div class="col">
                <h3 class="column-header-title">Assembly</h3>
                <p class="column-header-text">{{ getActiveTab.locations[3] }}</p>
              </div>
              <div class="col">
                <h3 class="column-header-title">Distribution</h3>
                <p class="column-header-text">{{ getActiveTab.locations[4] }}</p>
              </div>
            </div>
          </div>
        </div>
        
      </div>

    </header>

    <section id="section">

      <div class="wef-value-pool-container">
        <div class="section-navigation border-bottom">
          <div class="flex-section-navigation">
            <div class="col">
              <h3 class="section-navigation-title">Value pool</h3>
              <span class="icon icon-tooltip" v-tooltip="{ content: sectionTitles[0].text, trigger: 'manual', show: sectionTitles[0].isOpen }" @click="tooltipSecondEvent(0)"></span>
            </div>
            <div class="col">
              <button type="button" class="btn btn-transparent btn-flex-icon" @click="toggleSection(1)">
                <span class="section-navigation-title arrow">{{ (activeSections[1] ? 'Open' : 'Hide' ) }}</span>
                <span class="icon" :class="{ 'icon-chevron-bottom': activeSections[1], 'icon-chevron-top': !activeSections[1] }"></span>
              </button>
            </div>
          </div>
        </div>

        <div class="wef-value-pool" :class="{ 'hidden': activeSections[1] }">
          <div class="wef-value-legend">
            <div class="flex-legend">
              <div class="col">
                <h4 class="legend-text uppercase">Profitability</h4>
                <div class="flex-inner">
                  <span class="tag tag-purple"></span>
                  <span class="tag tag-dark-pink"></span>
                </div>
              </div>
              <div class="col">
                <h4 class="legend-text uppercase">Revenue (<span>bn</span> USD)</h4>
                <div class="flex-inner">
                  <span class="tag tag-light-purple"></span>
                  <span class="tag tag-pink"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="wef-value-content" v-if="isMounted">
            <div class="flex-flex-value-pool">
              <div class="col" v-for="i in 5" :key="`value-pool-${i}`">
                <double-bar-chart
                  :left-profitability="doubleBarChartValues.today_profitability[i - 1]"
                  :left-revenue="doubleBarChartValues.today_revenue[i - 1]"
                  :right-profitability="doubleBarChartValues.scenario_profitability[i - 1]"
                  :right-revenue="doubleBarChartValues.scenario_revenue[i - 1]"
                  :max-value="doubleBarChartValues.max_value"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="wef-geographic-concentration-container">
        <div class="section-navigation border-bottom">
          <div class="flex-section-navigation">
            <div class="col">
              <h3 class="section-navigation-title">Geographic concentration</h3>
              <span class="icon icon-tooltip" v-tooltip="{ content: sectionTitles[1].text, trigger: 'manual', show: sectionTitles[1].isOpen }" @click="tooltipSecondEvent(1)"></span>
            </div>
            <div class="col">
              <button type="button" class="btn btn-transparent btn-flex-icon" @click="toggleSection(2)">
                <span class="section-navigation-title arrow">{{ (activeSections[2] ? 'Open' : 'Hide' ) }}</span>
                <span class="icon" :class="{ 'icon-chevron-bottom': activeSections[2], 'icon-chevron-top': !activeSections[2] }"></span>
              </button>
            </div>
          </div>
        </div>

        <div class="wef-geographic-concentration" :class="{ 'hidden': activeSections[2] }">
          <div class="wef-geographic-concentration-legend">
            <h4 class="legend-text">L (Local)</h4>
            <h4 class="legend-text">N (National)</h4>
            <h4 class="legend-text">R (Regional)</h4>
            <h4 class="legend-text">G (Global)</h4>
          </div>

          <div class="wef-geographic-concentration-content" v-if="isMounted">

            <div class="table-legend">
              <span class="legend-text">L</span>
              <span class="legend-text">N</span>
              <span class="legend-text">R</span>
              <span class="legend-text">G</span>
            </div>

            <div class="flex-geographic-concentration">
              <div class="col" v-for="i in 5" :key="`geographic-concentration-${i}`">
                <table-chart
                  :index="i - 1"
                  :geographic-concentration-today="tableChartValues.geographic_contentration_today"
                  :geographic-concentration-scenario="tableChartValues.geographic_contentration_scenario"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="wef-structural-indicators-container">
        <div class="section-navigation border-bottom">
          <div class="flex-section-navigation">
            <div class="col">
              <h3 class="section-navigation-title">Structural indicators</h3>
              <span class="icon icon-tooltip" v-tooltip="{ content: sectionTitles[2].text, trigger: 'manual', show: sectionTitles[2].isOpen }" @click="tooltipSecondEvent(2)"></span>
            </div>
            <div class="col">
              <button type="button" class="btn btn-transparent btn-flex-icon" @click="toggleSection(3)">
                <span class="section-navigation-title arrow">{{ (activeSections[3] ? 'Open' : 'Hide' ) }}</span>
                <span class="icon" :class="{ 'icon-chevron-bottom': activeSections[3], 'icon-chevron-top': !activeSections[3] }"></span>
              </button>
            </div>
          </div>
        </div>

        <div class="wef-structural-indicators" :class="{ 'hidden': activeSections[3] }">
          <div class="wef-structural-indicators-legend">
            <h4 class="section-subtitle">Reliance on Economies of Scale (%fixed costs of total costs)</h4>
          </div>

          <div class="wef-structural-indicators-content" v-if="isMounted">
            <div class="flex-structural-indicators">
              <div class="col" v-for="i in 5" :key="`structural-indicator-1-${i - 1}`">
                <bar-chart
                  :l-value="structuralIndicatorsValues[0].left_value[i - 1]"
                  :r-value="structuralIndicatorsValues[0].right_value[i - 1]"
                  :max-value="structuralIndicatorsValues[0].max_value"
                  :formatted-value="true"
                />
              </div>
            </div>
          </div>

          <div class="wef-structural-indicators-legend">
            <h4 class="section-subtitle">Cost of labour (%labour costs of $1 Finished Goods)</h4>
          </div>

          <div class="wef-structural-indicators-content" v-if="isMounted">
            <div class="flex-structural-indicators">
              <div class="col" v-for="i in 5" :key="`structural-indicator-2-${i - 1}`">
                <bar-chart
                  :l-value="structuralIndicatorsValues[1].left_value[i - 1]"
                  :r-value="structuralIndicatorsValues[1].right_value[i - 1]"
                  :max-value="structuralIndicatorsValues[1].max_value"
                  :formatted-value="true"
                />
              </div>
            </div>
          </div>

          <div class="wef-structural-indicators-legend">
            <h4 class="section-subtitle">Other indirect costs (% other indirect costs of $1 Finished Goods)</h4>
          </div>

          <div class="wef-structural-indicators-content" v-if="isMounted">
            <div class="flex-structural-indicators">
              <div class="col" v-for="i in 5" :key="`structural-indicator-3-${i - 1}`">
                <bar-chart
                  :l-value="structuralIndicatorsValues[2].left_value[i - 1]"
                  :r-value="structuralIndicatorsValues[2].right_value[i - 1]"
                  :max-value="structuralIndicatorsValues[2].max_value"
                  :formatted-value="true"
                />
              </div>
            </div>
          </div>

          <div class="wef-structural-indicators-legend">
            <h4 class="section-subtitle">Labour intensiveness (#FTE per $1 Finished Goods)</h4>
          </div>

          <div class="wef-structural-indicators-content" v-if="isMounted">
            <div class="flex-structural-indicators">
              <div class="col" v-for="i in 5" :key="`structural-indicator-4-${i - 1}`">
                <bar-chart
                  :l-value="structuralIndicatorsValues[3].left_value[i - 1]"
                  :r-value="structuralIndicatorsValues[3].right_value[i - 1]"
                  :max-value="structuralIndicatorsValues[3].max_value"
                  :formatted-value="false"
                />
              </div>
            </div>
          </div>

          <div class="wef-structural-indicators-legend">
            <h4 class="section-subtitle">Innovation intensiveness (% of revenue invested into R&D)</h4>
          </div>

          <div class="wef-structural-indicators-content" v-if="isMounted">
            <div class="flex-structural-indicators">
              <div class="col" v-for="i in 5" :key="`structural-indicator-5-${i - 1}`">
                <bar-chart
                  :l-value="structuralIndicatorsValues[4].left_value[i - 1]"
                  :r-value="structuralIndicatorsValues[4].right_value[i - 1]"
                  :max-value="structuralIndicatorsValues[4].max_value"
                  :formatted-value="true"
                />
              </div>
            </div>
          </div>

          <div class="wef-structural-indicators-legend">
            <h4 class="section-subtitle">Supply concentration (% materials from top 3)</h4>
          </div>

          <div class="wef-structural-indicators-content" v-if="isMounted">
            <div class="flex-structural-indicators">
              <div class="col" v-for="i in 5" :key="`structural-indicator-6-${i - 1}`">
                <bar-chart
                  :l-value="structuralIndicatorsValues[5].left_value[i - 1]"
                  :r-value="structuralIndicatorsValues[5].right_value[i - 1]"
                  :max-value="structuralIndicatorsValues[5].max_value"
                  :formatted-value="true"
                />
              </div>
            </div>
          </div>

          <div class="wef-structural-indicators-legend">
            <h4 class="section-subtitle">Demand concentration (% supply to top 3)</h4>
          </div>

          <div class="wef-structural-indicators-content" v-if="isMounted">
            <div class="flex-structural-indicators">
              <div class="col" v-for="i in 5" :key="`structural-indicator-7-${i - 1}`">
                <bar-chart
                  :l-value="structuralIndicatorsValues[6].left_value[i - 1]"
                  :r-value="structuralIndicatorsValues[6].right_value[i - 1]"
                  :max-value="structuralIndicatorsValues[6].max_value"
                  :formatted-value="true"
                />
              </div>
            </div>
          </div>

          <div class="wef-structural-indicators-legend">
            <h4 class="section-subtitle">Competitive landscape (#players in sector)</h4>
          </div>

          <div class="wef-structural-indicators-content" v-if="isMounted">
            <div class="flex-structural-indicators">
              <div class="col" v-for="i in 5" :key="`structural-indicator-8-${i - 1}`">
                <bar-chart
                  :l-value="structuralIndicatorsValues[7].left_value[i - 1]"
                  :r-value="structuralIndicatorsValues[7].right_value[i - 1]"
                  :max-value="structuralIndicatorsValues[7].max_value"
                  :formatted-value="false"
                />
              </div>
            </div>
          </div>

          <div class="wef-structural-indicators-legend">
            <h4 class="section-subtitle">Relative wages (sector wage as % of national)</h4>
          </div>

          <div class="wef-structural-indicators-content" v-if="isMounted">
            <div class="flex-structural-indicators">
              <div class="col" v-for="i in 5" :key="`structural-indicator-9-${i - 1}`">
                <bar-chart
                  :l-value="structuralIndicatorsValues[8].left_value[i - 1]"
                  :r-value="structuralIndicatorsValues[8].right_value[i - 1]"
                  :max-value="structuralIndicatorsValues[8].max_value"
                  :formatted-value="true"
                />
              </div>
            </div>
          </div>



        </div>
      </div>

    </section>

    <footer id="footer">
      <div class="footer-content">
        <p class="footer-text">The information found on this tool is based on sampled data and best view projections and is intended to facilitate the development of a better understanding of Global Value Chains.</p>
      </div>
    </footer>

  </div>
</template>

<script>
  import Cotton from './data/Cotton'
  import Smartphone from './data/Smartphone'
  import Automobile from './data/Automobile'

  import CottonMapping from './data/cotton_mapping.json'
  import SmartphoneMapping from './data/smartphone_mapping.json'
  import AutomobileMapping from './data/auto_mapping.json'

  import DoubleBarChart from './components/DoubleBarChart'
  import TableChart from './components/TableChart'
  import BarChart from './components/BarChart'

  import TestTables from './components/TestTables'

  export default {
    name: 'App',
    components: {
      DoubleBarChart,
      TableChart,
      BarChart,
      TestTables
    },
    data() {
      return {
        tabs: [
          { active: true, title: 'Cotton', locations: ['Burkina Faso', 'Indonesia', 'Bangladesh', 'Bangladesh', 'France'] },
          { active: false, title: 'Smartphone', locations: ['Chile', 'China', 'Taiwan', 'China', 'India'] },
          { active: false, title: 'Automobile', locations: ['Brasil', 'Japan', 'Germany', 'Mexico', 'USA'] }
        ],
        activeSections: [false, false, false, false],
        sectionTitles: [
          { isOpen: false, text: 'The economic value pool indicates where and how much value is generated across the five stages of the GVC. This pillar is quantified by absolute revenues, relative profitability and net profits.  All three mega-trends will have an impact on both revenues and margins, but the nature of the impact differs per trend, per stage in the value chain and per value chain.' },
          { isOpen: false, text: 'This pillar represents the concentration of supply across geographic scope at each stage of a GVC, from local, national, regional through to global levels of sourcing. This analysis provides insights into the geographic footprint and spread of supply for each stage of the GVC.' },
          { isOpen: false, text: 'This pillar includes nine indicators to assess competitiveness at each stage along the GVC, represented by key performance indicators that can be compared across countries and value chains. They take into consideration interdependencies between countries and sectors that are relevant to value-chain participation. All values are aggregated averages for the country in which the respective stage is taking place.' }
        ],
        controlValues: [{
            value: 0,
            name: 'customer_expectations',
            title: 'Consumer Expectations',
            tooltip: [
              { isOpen: false, text: 'Less tailored products and services, slower lead times, lower service levels' },
              { isOpen: false, text: 'More tailored products and services, faster lead times, higher service levels' }
            ]
          },
          {
            value: 0,
            name: 'new_production_technologies',
            title: 'Production Technologies',
            tooltip: [
              { isOpen: false, text: 'Less production agility, larger batches, reduced efficiency' },
              { isOpen: false, text: 'More production agility, smaller batches, improved efficiency' }
             ]
          },
          {
            value: 0,
            name: 'flow_of_data',
            title: 'Flow of Data',
            tooltip: [
              { isOpen: false, text: 'Limited flows of data, closed access to data, reduced data reliability' },
              { isOpen: false, text: 'Frictionless flows of data, open access to data, improved data reliability' }
            ]
          },
          {
            value: 0,
            name: 'tech_enabled_platforms',
            title: 'Digital Platforms',
            tooltip: [
              { isOpen: false, text: 'Less reliance on digital platforms, limited scalability through digital, limited connectivity of users' },
              { isOpen: false, text: 'Full integration of digital platforms, more scalability through digital, seamless connectivity of users' }
            ]
          },
          {
            value: 0,
            name: 'climate_change',
            title: 'Climate Change',
            tooltip: [
              { isOpen: false, text: 'Reduced resource depletion, lower costing in of ecological externalities' },
              { isOpen: false, text: 'Increased resource depletion and costing in of externalities' }
             ]
          },
          {
            value: 0,
            name: 'risks_to_supply',
            title: 'Risks to Supply',
            tooltip: [
              { isOpen: false, text: 'Lower risks to supply and frequency thereof' },
              { isOpen: false, text: 'Higher risks to supply and frequency thereof' }
             ]
          },
          {
            value: 0,
            name: 'trade_barriers',
            title: 'Trade Barriers',
            tooltip: [
              { isOpen: false, text: 'Lower tariffs and regulatory barriers to trade, fewer trade disputes' },
              { isOpen: false, text: 'Higher tariffs and regulatory barriers to trade, more trade disputes' }
             ]
          }
        ],
        colTypes: ['revenue', 'profit_abs', 'profit', 'local', 'national', 'regional', 'global', 'reliance_on_eos', 'cost_of_labour', 'cost_of_energy', 'labour_intensiveness', 'innovation_intensiveness', 'supply_concentration', 'demand_concentration', 'competitive_landscape', 'relative_wages'],
        rowTypes: ['ex', 'pr', 'fo', 'as', 'di'],
        data: Cotton,
        translations: CottonMapping,
        weightedTableToday: [
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null]
        ],
        translationsTodayTable: [
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],

          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],

          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null]

        ],
        translationsToBeTable: [
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],

          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],

          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null]
        ],
        translationsCalcTable: [
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null]
        ],
        inputImplicationsTable: [
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],

          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],

          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null]
        ],
        weightedTableImplications: [
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null],
          [null, null, null, null, null]
        ],
        isMounted: false
      }
    },
    methods: {
      changeTab(index) {
        this.tabs.forEach((tab, index) => {
          tab.active = false
        })

        this.tabs[index].active = true
        this.isMounted = false

        if (this.tabs[0].active) {
          this.data = Cotton
          this.translations = CottonMapping
        } else if (this.tabs[1].active) {
          this.data = Smartphone
          this.translations = SmartphoneMapping
        }
        else if (this.tabs[2].active) {
          this.data = Automobile
          this.translations = AutomobileMapping
        }

        this.mainLogic()
      },
      toggleSection(index) {
        this.controlValues.forEach(controlValue => {
          controlValue.tooltip[0].isOpen = false
          controlValue.tooltip[1].isOpen = false
        })

        this.sectionTitles.forEach(sectionTitle => {
          sectionTitle.isOpen = false
        })
        
        setTimeout(() => {
          this.$set(this.activeSections, index, !this.activeSections[index])
        }, 100)
      },
      triggerSlider() {
        this.mainLogic()
      },
      tooltipEvent(index, row) {
        let val = !this.controlValues[index].tooltip[row].isOpen

        this.controlValues.forEach(controlValue => {
          controlValue.tooltip[0].isOpen = false
          controlValue.tooltip[1].isOpen = false
        })

        this.sectionTitles.forEach(sectionTitle => {
          sectionTitle.isOpen = false
        })

        this.$set(this.controlValues[index].tooltip[row], 'isOpen', val)
      },
      tooltipSecondEvent(index) {
        let val = !this.sectionTitles[index].isOpen

        this.controlValues.forEach(controlValue => {
          controlValue.tooltip[0].isOpen = false
          controlValue.tooltip[1].isOpen = false
        })

        this.sectionTitles.forEach(sectionTitle => {
          sectionTitle.isOpen = false
        })

        this.$set(this.sectionTitles[index], 'isOpen', val)
      },

      /* main logic*/

      mainLogic() {
        //
        // Dashboard
        //

        // economic value
        for(let i = 0; i < 5; i++) {
          this.setSingleCell(this.weightedTableToday, 0, i, this.data.inputTableToday[2][i])
        }

        // reliance on Eos, cost of labour, cost of energy, labour intensiveness
        for(let i = 0; i < 4; i++) {
          for(let j = 0; j < 5; j++) {
            let currentValue = this.data.inputTableToday[8 + i][j]
            let matchResult = this.data.weighingTable[1 + i].filter(weighingTableValue => currentValue >= weighingTableValue  && currentValue !== null).length
            let indexResult = this.data.weighingTable[0][matchResult - 1]
            let formulaResult = (!isNaN(indexResult)) ? indexResult - 1 : 5

            this.setSingleCell(this.weightedTableToday, 2 + i, j, formulaResult)
          }
        }

        // capabilities
        for(let i = 0; i < 5; i++) {
          let columnSum = 0

          for(let j = 0; j < 4; j++) {
            columnSum = this.weightedTableToday[2 + j][i] + columnSum
          }

          this.setSingleCell(this.weightedTableToday, 1, i, (columnSum / 4).toPrecision(3))
        }

        //innovation intensiveness, supply contentration, demand contentration, competitive landscape, relative wages
        for(let i = 0; i < 5; i++) {
          for(let j = 0; j < 5; j++) {
            let currentValue = this.data.inputTableToday[12 + i][j]
            let matchResult
            let indexResult

            if (i === 0 || i === 1 || i === 2) {
              matchResult = this.data.weighingTable[5 + i].filter(weighingTableValue => currentValue <= weighingTableValue && currentValue !== null).length
              indexResult = this.data.weighingTable[0][matchResult - 1]
            }
            else if (i === 3 || i === 4) {
              matchResult = this.data.weighingTable[5 + i].filter(weighingTableValue => currentValue >= weighingTableValue  && currentValue !== null).length
              indexResult = this.data.weighingTable[0][matchResult - 1] - 1
            } else {}

            if (i === 0) {
              let formulaResult = indexResult
              this.setSingleCell(this.weightedTableToday, 7 + i, j, formulaResult)
            } else if (i === 1 || i === 2) {
              let formulaResult = (indexResult) ? indexResult : ''
              this.setSingleCell(this.weightedTableToday, 7 + i, j, formulaResult)
            } else if (i === 3 || i === 4) {
              let formulaResult = (indexResult) ? indexResult : 5
              this.setSingleCell(this.weightedTableToday, 7 + i, j, formulaResult)
            }
          }
        }

        // strategic values
        for(let i = 0; i < 5; i++) {
          let strategicValueSum = 0

          let counter = 0
          
          for(let j = 0; j < 5; j++) {
            if (this.weightedTableToday[7 + j][i] !== '') {
              counter++
              strategicValueSum = this.weightedTableToday[7 + j][i] + strategicValueSum
            }
          }
          this.setSingleCell(this.weightedTableToday, 6, i, (strategicValueSum / counter).toPrecision(3))
        }

        //
        // TRANSLATIONS SHEET
        //

        // TODAY
        for(let i = 0; i < 5; i++) {
          // rev 
          this.setSingleCell(this.translationsTodayTable, 0, i, this.data.inputTableToday[0][i])

          // profit
          this.setSingleCell(this.translationsTodayTable, 2, i, this.data.inputTableToday[3][i])

          // overall costs
          this.setSingleCell(this.translationsTodayTable, 1, i, (this.translationsTodayTable[0][i] * this.translationsTodayTable[2][i]).toPrecision(3))
        }

        // local, national, regional, global
        for(let i = 0; i < 4; i++) {
          for(let j = 0; j < 5; j++) {
            this.setSingleCell(this.translationsTodayTable, 3 + i, j, this.data.inputTableToday[4 + i][j])
          }
        }

        // rest
        for(let i = 0; i < 9; i++) {
          for(let j = 0; j < 5; j++) {
            this.setSingleCell(this.translationsTodayTable, 7 + i, j, this.data.inputTableToday[8 + i][j])
          }
        }

        // TO-BE

        // calc table
        for(let i = 0; i < 4; i++) {
          for(let j = 0; j < 5; j++) {
            this.setSingleCell(this.translationsCalcTable, 0 + i, j, this.getLookUpValues(this.translations, this.controlValues, this.colTypes[3 + i], this.rowTypes[j], this.translationsTodayTable[3 + i][j]).toPrecision(3))
          }
        }

        for(let i = 0; i < 5; i++) {
          // rev 
          this.setSingleCell(this.translationsToBeTable, 0, i, this.getLookUpValues(this.translations, this.controlValues, this.colTypes[0], this.rowTypes[i], this.translationsTodayTable[0][i]).toPrecision(3))

          // profit
          this.setSingleCell(this.translationsToBeTable, 2, i, this.getLookUpValues(this.translations, this.controlValues, this.colTypes[2], this.rowTypes[i], this.translationsTodayTable[2][i]).toPrecision(3))

          // overall costs
          this.setSingleCell(this.translationsToBeTable, 1, i, (this.translationsToBeTable[0][i] * this.translationsToBeTable[2][i]).toPrecision(3))
        }
        
        // last 9 rows from translationsToBeTable
        for(let i = 0; i < 9; i++) {
          for(let j = 0; j < 5; j++) {
            this.setSingleCell(this.translationsToBeTable, 7 + i, j, this.getLookUpValues(this.translations, this.controlValues, this.colTypes[7 + i], this.rowTypes[j], this.translationsTodayTable[7 + i][j]).toPrecision(3))
          }
        }

        let translationsCalcTableValue = []
        for(let i = 0; i < 5; i++) {
          translationsCalcTableValue.push(this.getTranslationsCalcTableVerticalSum(this.translationsCalcTable, i))
        }

        // calc table sum and divide
        for(let i = 0; i < 4; i++) {
          for(let j = 0; j < 5; j++) {
            this.setSingleCell(this.translationsToBeTable, 3 + i, j, (this.translationsCalcTable[i][j] / translationsCalcTableValue[j]).toPrecision(3))
          }
        }

        this.setSingleCell(this.translationsToBeTable,  12, 0, null)
        this.setSingleCell(this.translationsToBeTable,  13, 4, null)      

        //INPUT TABLE IMPLICATIONS
        // rev
        for(let i = 0; i < 5; i++) {
          this.setSingleCell(this.inputImplicationsTable, 0, i, this.translationsToBeTable[0][i])
        }

        // profit
        for(let i = 0; i < 5; i++) {
          this.setSingleCell(this.inputImplicationsTable, 3, i, this.translationsToBeTable[2][i])
        }

        // profit_abs
        for(let i = 0; i < 5; i++) {
          this.setSingleCell(this.inputImplicationsTable, 2, i, (this.inputImplicationsTable[0][i] * this.inputImplicationsTable[3][i]).toPrecision(3))
        }

        // overall costs
        for(let i = 0; i < 5; i++) {
          this.setSingleCell(this.inputImplicationsTable, 1, i, (this.inputImplicationsTable[0][i] - this.inputImplicationsTable[2][i]).toPrecision(3))
        }

        // local, national, regional, global
        for(let i = 0; i < 4; i++) {
          for(let j = 0; j < 5; j++) {
            this.setSingleCell(this.inputImplicationsTable,  4 + i, j, this.translationsToBeTable[3 + i][j])
          }
        }

        // rest
        for(let i = 0; i < 9; i++) {
          for(let j = 0; j < 5; j++) {
            this.setSingleCell(this.inputImplicationsTable,  8 + i, j, this.translationsToBeTable[7 + i][j])
          }
        }
        
        //WEIGHTED TABLE IMPLICATIONS

        for(let i = 0; i < 5; i++) {
          this.setSingleCell(this.weightedTableImplications, 0, i, this.inputImplicationsTable[2][i])
        }

        // reliance on Eos, cost of labour, cost of energy, labour intensiveness
        for(let i = 0; i < 4; i++) {
          for(let j = 0; j < 5; j++) {
            let currentValue = this.inputImplicationsTable[8 + i][j]
            let matchResult = this.data.weighingTable[1 + i].filter(weighingTableValue => currentValue >= weighingTableValue  && currentValue !== null).length
            let indexResult = this.data.weighingTable[0][matchResult - 1]
            let formulaResult = (!isNaN(indexResult)) ? indexResult - 1 : 5

            this.setSingleCell(this.weightedTableImplications, 2 + i, j, formulaResult)
          }
        }

        // capabilities
        for(let i = 0; i < 5; i++) {
          let columnSum = 0

          for(let j = 0; j < 4; j++) {
            columnSum = this.weightedTableImplications[2 + j][i] + columnSum
          }

          this.setSingleCell(this.weightedTableImplications, 1, i, (columnSum / 4).toPrecision(3))
        }

        //innovation intensiveness, supply contentration, demand contentration, competitive landscape, relative wages
        for(let i = 0; i < 5; i++) {
          for(let j = 0; j < 5; j++) {
            let currentValue = this.inputImplicationsTable[12 + i][j]
            let matchResult
            let indexResult

            if (i === 0 || i === 1 || i === 2) {
              matchResult = this.data.weighingTable[5 + i].filter(weighingTableValue => currentValue <= weighingTableValue && currentValue !== null).length
              indexResult = this.data.weighingTable[0][matchResult - 1]
            }
            else if (i === 3 || i === 4) {
              matchResult = this.data.weighingTable[5 + i].filter(weighingTableValue => currentValue >= weighingTableValue  && currentValue !== null).length
              indexResult = this.data.weighingTable[0][matchResult - 1] - 1
            } else {}

            if (i === 0) {
              let formulaResult = indexResult
              this.setSingleCell(this.weightedTableImplications, 7 + i, j, formulaResult)
            } else if (i === 1 || i === 2) {
              let formulaResult = (indexResult) ? indexResult : ''
              this.setSingleCell(this.weightedTableImplications, 7 + i, j, formulaResult)
            } else if (i === 3 || i === 4) {
              let formulaResult = (indexResult) ? indexResult : 5
              this.setSingleCell(this.weightedTableImplications, 7 + i, j, formulaResult)
            } else {}
          }
        }

        // strategic values
        for(let i = 0; i < 5; i++) {
          let strategicValueSum = 0

          let counter = 0
          
          for(let j = 0; j < 5; j++) {
            if (this.weightedTableImplications[7 + j][i] !== '') {
              counter++
              strategicValueSum = this.weightedTableImplications[7 + j][i] + strategicValueSum
            }
          }
          this.setSingleCell(this.weightedTableImplications, 6, i, (strategicValueSum / counter).toPrecision(3))
        }

        this.isMounted = true
      }

      /* end of main logic*/
    },
    mounted() {
      window.addEventListener('scroll', () => {
        let introHeight = document.querySelector('.wef-widget-header').offsetHeight
        let scrollTop = window.scrollY || document.documentElement.scrollTop || 0

        document.getElementById('header').classList[scrollTop > introHeight ? 'add' : 'remove']('sticky')

        if (document.getElementById('header').classList.contains('sticky')) {
          document.getElementById('section').style.marginTop = `${document.getElementById('header').offsetHeight}px`
        } else {
          document.getElementById('section').style.marginTop = `0px`
        }
      })

      this.mainLogic()
    },
    computed: {
      getActiveTab() {
        return this.tabs.find((t) => t.active)
      },
      doubleBarChartValues() {
        if (this.isMounted) {
          let mainObject = {}
          
          let todayRevenue = this.data.inputTableToday[0].map(e => {
            return Math.round(e * 100) / 100
          })
          
          let todayProfitability = this.data.inputTableToday[3].map(e => {
            return Math.round(e * 100) / 100
          })

          let scenarioRevenue = this.inputImplicationsTable[0].map(e => {
            return Math.round(e * 100) / 100
          })

          let scenarioProfitability = this.inputImplicationsTable[3].map(e => {
            return Math.round(e * 100) / 100
          })

          let maxValue = Math.max(...[...todayRevenue, ...scenarioRevenue])

          mainObject.max_value = maxValue
          mainObject.today_revenue = todayRevenue
          mainObject.today_profitability = todayProfitability
          mainObject.scenario_revenue = scenarioRevenue
          mainObject.scenario_profitability = scenarioProfitability

          return mainObject
        }
      },
      tableChartValues() {
        if (this.isMounted) {
          let mainObject = {}

          let geographicContentrationToday = []
          let geographicContentrationScenario = []

          for(let i = 0; i < 4; i++) {
            let formatedArray = this.data.inputTableToday[4 + i].map(e => {
              return Math.round(e * 100) / 100
            })

            geographicContentrationToday.push(formatedArray)
          }

          for(let i = 0; i < 4; i++) {
            let formatedArray = this.inputImplicationsTable[4 + i].map(e => {
              return Math.round(e * 100) / 100
            })

            geographicContentrationScenario.push(formatedArray)
          }

          mainObject.geographic_contentration_today = geographicContentrationToday
          mainObject.geographic_contentration_scenario = geographicContentrationScenario
          
          return mainObject
        }
      },
      structuralIndicatorsValues() {
        if (this.isMounted) {
          let mainArray = []

          for(let i = 0; i < 9; i++ ) {
            let mainObject = {}

            let formatedLeftValue = this.data.inputTableToday[8 + i].map(e => {
              return Math.round(e * 100) / 100
            })

            let formatedRightValue = this.inputImplicationsTable[8 + i].map(e => {
              return Math.round(e * 100) / 100
            })

            let maxValue = Math.max(...[...formatedLeftValue, ...formatedRightValue])

            mainObject.left_value = formatedLeftValue
            mainObject.right_value = formatedRightValue
            mainObject.max_value = maxValue

            mainArray.push(mainObject)
          }

          return mainArray
        }
      }
    }
  }
</script>
