const data = {
  inputTableToday: [
    [23.70, 103.05, 85.75, 53.15, 22.48],
    [16.12, 100.99, 66.89, 44.65, 17.31],
    [7.58, 2.06, 18.87, 8.50, 5.17],
    [0.32, 0.02, 0.22, 0.16, 0.23],

    [0.11, 0.10, 0.10, 0.10, 0],
    [0.31, 0.8, 0.8, 0.8, 0],
    [0, 0, 0, 0, 0],
    [0.58, 0.1, 0.1, 0.1, 1],

    [0.19, 0.3, 0.42, 0.09, 0.08],
    [0.08, 0.3, 0.09, 0.05, 0.1],
    [0.08, 0.3, 0.05, 0.08, 0.05],
    [312.1, 433, 36.8, 1.1, 12.9],
    [0.01, 0.01, 0.11, 0.01, 0.06],
    [null, 0.64, 0.7, 0.95, 0.4],
    [0.72, 0.32, 0.52, 0.39, null],
    [24, 16, 14, 25, 11],
    [2.46, 0.72, 1.15, 0.69, 1.37]
  ],
  weighingTable: [
    [5, 4, 3, 2, 1],
    [0.03, 0.05, 0.08, 0.1, 1],
    [0.03, 0.06, 0.09, 0.12, 1],
    [0.03, 0.06, 0.09, 0.12, 1],
    [60, 120, 180, 240, 1000000],
    [1, 0.04, 0.03, 0.02, 0.01],
    [1, 0.75, 0.5, 0.25, 0],
    [1, 0.75, 0.5, 0.25, 0],
    [25, 50, 500, 5000, 10000000000],
    [0.8, 0.9, 1, 1.1, 5]
  ]
}

export default data
