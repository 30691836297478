<template>
  <div class="bar-chart">
    <div class="bar-chart-header">
      <div class="flex-bar-chart">
        <div class="col">
          <h5 class="component-title">Today</h5>
        </div>
        <div class="col">
          <h5 class="component-title">Scenario</h5>
        </div>
      </div>
    </div>
    <div class="bar-chart-body">
      <div class="flex-content">
        <div class="col col-left">
          <div class="revenue" :style="{ 'height': `${getLeftHeight}%` }"></div>
        </div>
        <div class="col col-right">
          <div class="revenue" :style="{ 'height': `${getRightHeight}%` }"></div>
        </div>
      </div>
      <div class="flex-footer">
        <div class="col">
          <span class="component-value">{{ getFormattedValue(lValue) }}</span>
        </div>
        <div class="col">
          <span class="component-value">{{ getFormattedValue(rValue) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BarChart',
  props: {
    lValue: Number,
    rValue: Number,
    maxValue: Number,
    formattedValue: Boolean
  },
  data() {
    return {
      leftHeight: null,
      rightHeight: null
    }
  },
  methods: {
    getFormattedValue(value) {
      if (this.formattedValue) {
        return `${parseInt(value * 100)}%`
      } else {
        return parseInt(value)
      }
    }
  },
  computed: {
    getLeftHeight() {
      this.leftHeight = (this.maxValue / this.lValue)
      return (this.leftHeight === 1) ? 100 : (100 / this.leftHeight)
    },
    getRightHeight() {
      this.rightHeight = (this.maxValue / this.rValue)
      return (this.rightHeight === 1) ? 100 : (100 / this.rightHeight)
    },
  }
}
</script>